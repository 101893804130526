const dev = {
  api: {
    invokeUrl: "https://ivairrt8e5.execute-api.ap-south-1.amazonaws.com/dev",
    coursesUrl: "https://c1j5ihself.execute-api.ap-south-1.amazonaws.com/dev",
    woUrl: "https://a5bgoepr7f.execute-api.ap-south-1.amazonaws.com/dev",
  },
  cognito: {
    REGION: "ap-south-1",
    USER_POOL_ID: "ap-south-1_hWT9S1EC4",
    APP_CLIENT_ID: "36o8ns8k5kig7hcvp5eq969f58",
  },
  payemntSecretName: "ibc_razorpay",
  razorpayId: "rzp_test_pOy0pZDFzJ8md8",
};

const prodA = {
  api: {
    invokeUrl: "https://03ln13y8l4.execute-api.ap-south-1.amazonaws.com/prod",
    coursesUrl: "https://8ypugvxsvf.execute-api.ap-south-1.amazonaws.com/prod",
    woUrl: "https://bowh7gnm9d.execute-api.ap-south-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "ap-south-1",
    USER_POOL_ID: "ap-south-1_Nf35p6lDj",
    APP_CLIENT_ID: "733v0mvjcuhgo61o655ig4i4vd",
  },
  payemntSecretName: "",
  razorpayId: "",
};
let config = dev;
if (process.env.REACT_APP_STAGE) {
  switch (process.env.REACT_APP_STAGE.trim()) {
    case "dev":
      config = dev;
      break;
    case "prodA":
      config = prodA;
      break;
    default:
      config = dev;
      break;
  }
}

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
